// MUTATIONS
const SET_STATUS_MODEL_DONATION = "Other/SET_STATUS_MODEL_DONATION";
const SET_TOGGLE_NOTIFI = "Other/SET_TOGGLE_NOTIFI";
const SET_ALERT = "Other/SET_ALERT";
const SET_USER_DATA = "Auth/SET_DATA_USER";
const CLEAR_USER_DATA = "Auth/CLEAR_USER_DATA";
const TOGGLE_CONFIRM_ALERT = "Other/TOGGLE_CONFIRM_ALERT";
const SET_CONFIRM_ALERT = "Other/SET_CONFIRM_ALERT";
const SET_ABOUT_SITE = "Pages/SET_ABOUT_SITE";
const SET_TERMS = "Pages/SET_TERMS";
const SET_DONATIONS_SHOW = "Pages/SET_DONATIONS_SHOW";
const SET_DATA_MODEL = "Pages/SET_DATA_MODEL";
const SET_DONATIONS_RECORDES = "Pages/SET_DONATIONS_RECORDES";
const SET_CASH_WALLET = "Pages/SET_CASH_WALLET";
const SET_WALLET = "Pages/SET_WALLET";
const SET_PROFILE_USER = "Auth/SET_PROFILE_USER";
const SET_INIT_LOADING = "Auth/SET_INIT_LOADING";
const SET_DATA_NOTIFI = "Other/SET_DATA_NOTIFI";
const SET_DESTROYED_HOME = "Pages/SET_DESTROYED_HOME";
const SET_STATUS_ACTION = "Other/SET_STATUS_ACTION";
const SET_WAIT = "Other/SET_WAIT";

// GETTERS
const GET_TOGGLE_NOTIFI = "Other/GET_TOGGLE_NOTIFI";
const GET_STATUS_MODEL_DONATION = "Other/GET_STATUS_MODEL_DONATION";
const GET_ALERT = "Other/GET_ALERT";
const GET_STATUS_CONFIRM_ALERT = "Other/GET_STATUS_CONFIRM_ALERT";
const GET_ABOUT_SITE = "Pages/GET_ABOUT_SITE";
const GET_TERMS = "Pages/GET_TERMS";
const GET_DONATIONS_SHOW = "Pages/GET_DONATIONS_SHOW";
const GET_DATA_MODEL = "Pages/GET_DATA_MODEL";
const GET_DONATIONS_RECORDES = "Pages/GET_DONATIONS_RECORDES";
const GET_CASH_WALLET = "Pages/GET_CASH_WALLET";
const GET_PROFILE_USER = "Auth/GET_PROFILE_USER";
const GET_INIT_LOADING = "Auth/GET_INIT_LOADING";
const GET_NOTIFI = "Other/GET_NOTIFI";
const GET_WAIT_DONATION = "Other/GET_WAIT_DONATION";
const GET_WAIT_WALLET = "Other/GET_WAIT_WALLET";

// ACTIONS
const REGISTER = "Auth/REGISTER";
const SIGN_IN = "Auth/SIGN_IN";
const SIGN_OUT = "Auth/SIGN_OUT";
const ABOUT_SITE = "Pages/ABOUT_SITE";
const TERMS = "Pages/TERMS";
const DONATIONS_SHOW = "Pages/DONATIONS_SHOW";
const DONATE = "Pages/DONATE";
const DONATIONS_RECORDS = "Pages/DONATIONS_RECORDS";
const GET_WALLET = "Pages/GET_WALLET";
const PROFILE_USER = "Auth/PROFILE_USER";
const UPDATE_IMAGE = "Auth/UPDATE_IMAGE";
const UPDATE_PROFILE = "Auth/UPDATE_PROFILE";
const COUNT_NOTIFI = "Other/GET_COUNT_NOTIFI";
const NOTIFI = "Other/GET_NOTIFI";
const CONTACT_US = "Pages/GET_CONTACT_US";
const SEARCH = "Pages/SEARCH";
const SET_TIME_ACTION = "Other/SET_TIME_ACTION";
//
export {
  SET_STATUS_MODEL_DONATION,
  SET_TOGGLE_NOTIFI,
  SET_ALERT,
  SET_USER_DATA,
  TOGGLE_CONFIRM_ALERT,
  SET_CONFIRM_ALERT,
  SET_ABOUT_SITE,
  SET_TERMS,
  SET_DONATIONS_SHOW,
  SET_DATA_MODEL,
  SET_DONATIONS_RECORDES,
  CLEAR_USER_DATA,
  SET_CASH_WALLET,
  SET_WALLET,
  SET_PROFILE_USER,
  SET_INIT_LOADING,
  SET_DATA_NOTIFI,
  SET_DESTROYED_HOME,
  SET_STATUS_ACTION,
  SET_WAIT,
  //
  GET_TOGGLE_NOTIFI,
  GET_STATUS_MODEL_DONATION,
  GET_ALERT,
  GET_STATUS_CONFIRM_ALERT,
  GET_ABOUT_SITE,
  GET_TERMS,
  GET_DONATIONS_SHOW,
  GET_DATA_MODEL,
  GET_DONATIONS_RECORDES,
  GET_CASH_WALLET,
  GET_PROFILE_USER,
  GET_INIT_LOADING,
  GET_NOTIFI,
  GET_WAIT_DONATION,
  GET_WAIT_WALLET,
  //
  REGISTER,
  SIGN_IN,
  SIGN_OUT,
  ABOUT_SITE,
  TERMS,
  DONATIONS_SHOW,
  DONATE,
  DONATIONS_RECORDS,
  GET_WALLET,
  PROFILE_USER,
  UPDATE_IMAGE,
  UPDATE_PROFILE,
  COUNT_NOTIFI,
  NOTIFI,
  CONTACT_US,
  SEARCH,
  SET_TIME_ACTION,
};
