<template>
  <main id="app" class="bg-light">
    <router-view />
  </main>
</template>

<script>
export default {};
</script>
<style lang="scss">
#app {
  height: 100%;
}
</style>
